@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.IndexBlockPanel {
  border-radius: tokens.$bpk-border-radius-md;
  background-color: tokens.$bpk-canvas-contrast-day;

  &__container {
    width: 100%;
    padding: tokens.bpk-spacing-xxl() tokens.bpk-spacing-lg();
    text-align: center;
  }

  &__desc {
    margin-top: tokens.bpk-spacing-sm();
  }

  &__itemList {
    display: flex;
    margin-top: tokens.bpk-spacing-xl();
    justify-content: space-around;
    gap: tokens.bpk-spacing-xl();

    @include breakpoints.bpk-breakpoint-small-tablet {
      margin-top: tokens.bpk-spacing-lg();
      flex-flow: column;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__item {
    flex: 1 1 0;
    text-align: center;
  }

  &__imgBox {
    width: tokens.$bpk-one-pixel-rem * 218;
    height: tokens.$bpk-one-pixel-rem * 218;
    margin: auto;

    img {
      height: tokens.$bpk-one-pixel-rem * 186;
    }
  }

  &__itemHead {
    margin-top: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-small-tablet {
      margin-top: tokens.bpk-spacing-base();
    }
  }

  &__itemBody {
    margin-top: tokens.bpk-spacing-sm();
    color: tokens.$bpk-text-primary-day;
  }
}
